export const it = {
  TOOLNAME: 'E-BOOKING TOOL',
  BRANDS: {
    versace: 'Versace',
    'michael-kors': 'Michael Kors',
    'jimmy-choo': 'Jimmy Choo',
  },
  ROLE: {
    ADMIN: 'Amministratore',
    OPERATOR: 'Operatore',
    EMPLOYEE: 'Dipendente',
    GUEST: 'Ospite',
    EXTERNAL_USER: 'Ospite',
  },
  NAVBAR: {
    item_LOGIN_EMPLOYEE: 'LOGIN',
    item_PRIVACY_POLICY: 'INFORMATIVA PRIVACY',
    item_USERS: 'GESTIONE UTENTI',
    item_EVENTS: 'GESTIONE EVENTI',
    item_INVITATIONS: 'Elenco Eventi',
    item_BOOKINGS: 'I Miei appuntamenti',
    item_LOGOUT: 'Logout',
  },
  FOOTER: {
    PRIVACY_POLICY: 'INFORMATIVA PRIVACY',
  },
  PAGE_NOT_FOUND: {
    PAGE_NOT_FOUND: 'Pagina non trovata',
    PAGE_NOT_FOUND_MESSAGE:
      'Spiacenti, non siamo riusciti a trovare la pagina che state cercando',
  },
  SPLASH_PAGE: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
  },
  MOBILE_PAGE: {
    TITLE_MESSAGE: 'Questo sito non è disponibile sui dispositivi mobili',
    TEXT_MESSAGE:
      'Per navigare in questo sito web, utilizzare il computer portatile.',
  },
  EVENT_ENDED: {
    TITLE: 'evento terminato',
    MESSAGE: "Siamo spiacenti ma l'evento che sta cercando è terminato.",
  },
  LOGIN_EXTERNAL: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
    LOGIN: 'Accedi',
    TEXT: "Per accedere, utilizzare l'indirizzo e-mail con cui si è ricevuto l'invito all'evento e come password inserire quella che vi abbiamo inviato",
    CONTACT: "Contattare l'assistenza",
    PASSWORD: 'Password',
    EMAIL_ADDRESS: 'Indirizzo Email',
    ERROR:
      'Credenziali non valide, verificare che siano state inserite correttamente',
    CAPRI_EMPLOYEE: 'Sei un dipendente Capri Holdings?',
    LOGIN_EMPLOYEE: 'Login come Dipendente',
  },
  LOGIN_EMPLOYEE: {
    E_PLANNING_TOOL: '$t(TOOLNAME)',
    LOGIN: 'Accedi',
    BUTTON_TEXT: 'Accedi con sso',
    CONTACT: "Contattare l'assistenza",
    CAPRI_EMPLOYEE: 'Non sei un dipendente Capri Holdings?',
    LOGIN_GUEST: 'Login come Ospite',
    ERROR: 'Login non riuscita',
  },
  USER_MANAGEMENT: {
    PAGE_TITLE: 'Gestione Utenti',
    BRAND_TITLE: 'Società',
    TABLE_FILTER_LABEL:
      "Ricerca dell'utente per indirizzo e-mail o nome del contatto",
    CONTACTNAME: 'Nome Contatto',
    EMAIL: 'Indirizzo E-Mail',
    ROLE: 'Ruolo',
    ROW_PER_PAGE: 'Righe per pagina:',
    DIPLAYED_ROWS: '{{from}}–{{to}} di {{count}}',
  },
  EXTERNAL_USER_PHONE_NUMBER_MODAL: {
    TITLE: 'Teniamoci in contatto',
    TEXT: 'Abbiamo bisogno del vostro numero di cellulare per contattarvi.',
    PHONE_NUMBER: 'Numero di telefono *',
    VALIDATION_TEXT_FIRST_PIECE: 'Ho letto e compreso l’',
    VALIDATION_TEXT_SECOND_PIECE: 'Informativa Privacy e Cookie Policy',
    SEND_NUMBER: 'Manda il Numero',
    RESPONSE_ERROR_MESSAGE: 'Qualcosa è andato storto...',
  },
  MODAL_CLOSING_PAGE: {
    TITLE: 'Siete sicuri di voler chiudere questa pagina?',
    TEXT: "Tutti i dati non possono essere recuperati e l'azione non può essere annullata.",
    BUTTON_PRIMARY: 'Cancella',
    BUTTON_SECONDARY: 'Chiudi la Pagina',
  },
  EVENT_DOORMAN: {
    TITLE: 'Checklist delle persone prenotate',
    SUB_TITLE:
      'In questa pagina è possibile fare il check di presenza di una persona prenotata per uno specifico slot.',
    NOTE: 'Messaggio:',
    CLOSE: 'chiudi',
    SEARCH: 'Ricerca',
    NOT_FOUND: 'Nessun risultato trovato',
    SHOW_ONLY_PRESENT: 'Mostra solo i presenti',
    DOWNLOAD_CHECKLIST: 'Scarica la checklist',
    LOAD_MORE: 'Più risultati',
  },
  EVENT_LIST: {
    BUTTON_TEXT: 'Invita Ospiti',
  },
  EVENT_MODAL: {
    NOTE: 'Note',
    EDIT: 'Modifica',
    DELETE: 'Elimina',
  },
  EVENT_PEOPLE_BOOKED: {
    TITLE: 'Dettagli delle persone prenotate',
    SUB_TITLE:
      'In questa pagina è possibile visualizzare i dettagli delle persone prenotate per uno specifico slot.',
    NOTE: 'Messaggio:',
    CLOSE: 'chiudi',
    BOOKED_BY: 'Prenotato da: ',
  },
  EVENT_BEHALF_OF_BOOKING: {
    TITLE: 'Prenotazione per conto di terzi',
    SUB_TITLE:
      "È possibile effettuare prenotazioni allo slot di orario dell'evento per conto di terzi, indicando nome, cognome e (opzionalmente) l'email. ",
  },
  DATE_MOMENT: {
    HOURS_FORMAT: 'HH:mm',
    DAY_STRING_FORMAT: 'dddd',
    NUMBER_DAY_FORMAT: 'DD',
    MONTH_FORMAT: 'MMMM',
    YEAR_FORMAT: 'YYYY',
    FULL_DATE: '$t(DATE_MOMENT.DAY_STRING_FORMAT) DD, MMMM YYYY',
    DATE_FORMAT: 'YYYY-MM-DD',
    DATE_ORDINARY_FORMAT: 'DD MMMM YYYY',
  },
  INVITE_GUEST: {
    SUB_TITLE: `È possibile invitare un massimo di {{guests}} ospiti all'evento. Per invitare un ospite è necessario completare il suo nome,
    cognome e indirizzo e-mail; un'e-mail con il link di invito verrà inviata all'ospite. Non è possibile cancellare un ospite invitato.
    I campi contrassegnati da * sono obbligatori. Eventuali ospiti già invitati da altri utenti non saranno conteggiati tra i tuoi possibili invitati.`,
    TEXT_BUTTON_ONE: 'Cancella',
    TEXT_BUTTON_TWO: 'Invita Ospiti',
    TITLE: 'Invita Ospiti',
    GUEST: 'Ospite',
    FIRST_NAME: 'Nome',
    LAST_NAME: 'Cognome',
    EMAIL_ADDRESS: 'Indirizzo Email',
    RESPONSE_SUCCESS: 'Ospiti invitati con successo',
    RESPONSE_ERROR:
      'Si è verificato un problema con gli inviti per gli ospiti, provare di nuovo',
  },
  BOOKING_MODAL: {
    SUB_TITLE:
      'Se lo desiderate, aggiungete un messaggio, quindi fate clic sul pulsante "Prenota appuntamento" per inviare la vostra prenotazione.',
    TEXT_BUTTON_ONE: 'Annulla',
    TEXT_BUTTON_TWO: 'Prenota Appuntamento',
    TITLE: 'PRENOTA il tuo APPUNTAMENTO',
    MESSAGE: 'Lascia un messaggio',
    ERROR:
      'Si è verificato un problema con la sua prenotazione, la preghiamo di riprovare',
    SUCCESS: 'Prenotazione effettuata con successo!',
    ACCESS_EVENT: "Scegliere come accedere all'evento *",
    IN_PERSON: 'In presenza',
    REMOTE: 'Online',
    RESPONSE_SUCCESS: 'Appuntamento modificato con successo',
    RESPONSE_ERROR: 'Si è verificato un problema, provare di nuovo',
    DELETE_RESPONSE_SUCCESS: 'Appuntamento eliminato con successo',
    DELETE_RESPONSE_ERROR: 'Si è verificato un problema, provare di nuovo',
    BOOKING_AS: "Ti stai prenotando all'evento come: ",
    GUESTS: {
      DESCRIPTION:
        'Puoi aggiungere alla tua prenotazione altre persone come ospiti. Posti disponibili: {{maxGuests}}',
      REMOVE_BUTTON: 'Rimuovi',
      ADD_BUTTON: 'Aggiungi un ospite',
    },
  },
  EVENT_MANAGEMENT: {
    TITLE: 'Gestione Eventi',
    BUTTON_DOWNLOAD_BOOKINGS: 'SCARICA PRENOTAZIONI',
    BUTTON_TEXT: 'Crea Evento',
    EDIT_BUTTON: 'Modifica Evento',
    DELETE_BUTTON: 'Elimina Evento',
    NO_TITLE: 'Non ci sono ancora eventi.',
    NO_MESSAGE: '',
    LINK_COPIED: 'Link copiato negli appunti.',
  },
  EVENT_SLOT_DETAIL: {
    PEOPLE_BOOKED: '{{bookedSpots}}/{{slotLimit}} persone prenotate',
    VIEW_BOOKED_PEOPLE: 'Vai al dettaglio delle prenotazioni',
    DOORMAN: 'Vai alla checklist',
    BEHALF_OF_BOOKING: 'Prenota per conto di terzi',
    DISABLE_SLOT: 'Disabilita slot',
    ENABLE_SLOT: 'Abilita Slot',
    BOOK_SLOT: 'Prenota appuntamento',
  },
  EVENT_SLOT_DELETE: {
    MODAL_TITLE: 'Vuoi cancellare lo slot?',
    MODAL_TEXT:
      'Ci sono delle prenotazioni ({{bookedSpots}}) effettuate per questo slot.',
    MODAL_WARNING:
      'Cancellando lo slot, verranno cancellate anche le prenotazioni.',
    DELETE_RESPONSE_ERROR: 'Si è verificato un problema, provare di nuovo',
    DELETE_RESPONSE_SUCCESS: 'Slot cancellato con successo',
    BUTTON_CANCEL: 'Indietro',
    BUTTON_DELETE: 'Cancella slot',
  },
  EVENT_SLOT_EDIT: {
    DISABLE: {
      MODAL_TITLE: 'Disabilita lo slot',
      MODAL_TEXT:
        'Disabilitando lo slot, gli utenti non potranno più prenotarsi per quella fascia di orario, mentre le prenotazioni già esistenti rimarranno attive.',
      BUTTON_CONFIRM: 'Disabilita slot',
    },
    ENABLE: {
      MODAL_TITLE: 'Riabilita lo slot',
      MODAL_TEXT:
        'Per riabilitare lo slot è necessario impostare il limite di persone che si possono prenotare.',
      BUTTON_CONFIRM: 'Riabilita slot',
      SLOT_SIZE: 'Limite slot',
    },
    RESPONSE_ERROR: 'Si è verificato un problema, provare di nuovo',
    RESPONSE_SUCCESS: 'Slot modificato con successo',
    BUTTON_CANCEL: 'Indietro',
  },
  EVENT_TYPE_TO_CREATE: {
    TITLE: 'Crea evento',
    TEXT: "Selezionare il luogo dell'evento e il tipo di evento che si desidera creare",
    LOCATION_TITLE: "Luogo dell'evento *",
    CATEGORY_TITLE: "Tipo dell'evento *",
    BUTTON_ONE_TEXT: 'Indietro',
    BUTTON_TWO_TEXT: "Personalizza l'evento",
  },
  EVENT_CREATE_EDIT: {
    CREATE_TITLE: 'crea evento: {{location}} - {{category}}',
    CREATE_SUB_TITLE:
      "Quando si fa clic sul pulsante “Crea evento“, l'evento verrà pubblicato. Solo gli utenti invitati possono accedere alla prenotazione.  " +
      "Gli orari indicati si basano sul fuso orario del luogo in cui si svolge l'evento.",
    CREATE_SUB_TITLE_REQUIRED_FIELDS:
      'I campi contrassegnati da * sono obbligatori.',
    TEXT_BUTTON_ONE: 'Indietro',
    CREATE_TEXT_BUTTON_TWO: 'Crea evento',
    CREATE_RESPONSE_SUCCESS: 'Evento creato con successo',
    CREATE_RESPONSE_ERROR:
      'Si è verificato un problema, controllare i campi inseriti',
    EDIT_TITLE: 'Modifica evento: {{location}} - {{category}}',
    EDIT_SUB_TITLE:
      "Facendo clic sul pulsante “Salva evento”, l'evento verrà pubblicato con le modifiche apportate. " +
      'Se si rimuove una delle date, gli appuntamenti già prenotati in quelle date saranno cancellati e gli utenti saranno avvisati.' +
      'Solo gli utenti invitati possono accedere alla prenotazione.',
    EDIT_SUB_TITLE_REQUIRED_FIELDS:
      'I campi contrassegnati da * sono obbligatori.',
    EDIT_TEXT_BUTTON_TWO: 'Salva evento',
    EDIT_RESPONSE_SUCCESS: 'Evento modificato con successo',
    EDIT_RESPONSE_ERROR:
      'Si è verificato un problema, controllare i campi inseriti',
    GENERAL_INFO: {
      TITLE: 'Informazioni generali',
      EVENT_NAME: 'Nome evento*',
      START_DATE: 'Data inizio*',
      END_DATE: 'Data fine*',
      GUESTS: 'Ospiti per dipendente*',
      SLOT_DURATION: 'Durata dello slot*',
      ALLOW_MULTIPLE_BOOKINGS: 'Consenti prenotazioni multiple',
      MIN_BOOKING_TIME: 'Tempo di anticipo minimo (ore)',
    },
    WEEKLY_SCHEDULE: {
      TITLE: 'Orari settimanali',
      EDIT_SUBTITLE:
        'In fase di modifica non è possibile cambiare le fasce orarie settimanali impostate',
      WEEK_DAY_NUMBER: {
        1: 'Lunedì',
        2: 'Martedì',
        3: 'Mercoledì',
        4: 'Giovedì',
        5: 'Venerdì',
        6: 'Sabato',
        7: 'Domenica',
      },
      CLOSED: 'Non disponibile',
      START_TIME: 'Orario apertura',
      END_TIME: 'Orario chiusura',
      DELETE_SLOT: 'Cancella slot',
    },
    SLOT_SIZE: {
      TITLE: 'Gestione dei limiti degli slots',
      SUB_TITLE:
        "Le date degli slot devono coprire l'intera durata dell'evento.",
      START_DATE: 'Data inizio*',
      END_DATE: 'Data fine*',
      SLOT_SIZE: 'Limite slot*',
      BUTTON_ADD: 'Aggiungi slot',
      BUTTON_DELETE: 'Rimuovi slot',
    },
    RESERVATION_OPENINGS: {
      TITLE: 'Date per impiegati e esterni',
      VERSACE_LABEL: 'Impiegati Versace*',
      VERSACE_START_DATE: 'Data inizio Versace*',
      VERSACE_END_DATE: 'Data fine Versace*',
      CAPRI_LABEL: 'Impiegati Capri*',
      CAPRI_START_DATE: 'Data inizio Capri*',
      CAPRI_END_DATE: 'Data fine Capri*',
      EXTERNAL_LABEL: 'Utenti esterni*',
      EXTERNAL_START_DATE: 'Data inizio Esterni*',
      EXTERNAL_END_DATE: 'Data fine Esterni*',
    },
    EXTRAORDINARY_SCHEDULE: {
      TITLE: 'Gestione straordinaria',
      EDIT_SUBTITLE:
        'In fase di modifica non è possibile cambiare le fasce orarie straordinarie. In caso contrario le modifiche apportate non saranno applicate in fase di salvataggio.',
      CLOSED: 'Non disponibile',
      START_TIME: 'Orario apertura',
      END_TIME: 'Orario chiusura',
      DELETE_SLOT: 'Cancella slot',
      ADD_DATE: 'Aggiungi data',
    },
  },
  EVENT_DELETE: {
    TITLE: 'Cancella evento',
    TEXT: "Sei sicuro di voler eliminare l'evento",
    ADDITIONAL_TEXT:
      "Gli utenti che si sono prenotati riceveranno un'email per avvisarli che l'appuntamento verrà cancellato.",
    BUTTON_CANCEL: 'INDIETRO',
    BUTTON_DELETE: 'CANCELLA EVENTO',
    RESPONSE_SUCCESS: 'Evento eliminato con successo',
    RESPONSE_ERROR: 'Si è verificato un problema, provare di nuovo',
  },
  EVENT_BOOKINGS_DOWNLOAD: {
    TITLE: "Scarica le prenotazioni dell'evento",
    SUB_TITLE:
      "In questa pagina è possibile scaricare l'elenco delle persone prenotate per uno specifico range temporale.",
    ADDITIONAL_TEXT:
      "Selezionare almeno una delle date per filtare l'elenco delle prenotazioni.",
    START_DATE: 'Data inizio',
    END_DATE: 'Data fine',
    TEXT_BUTTON_ONE: 'Chiudi',
    TEXT_BUTTON_TWO: 'Scarica prenotazioni',
  },
  APPOINTMENTS: {
    TITLE: 'I Miei Appuntamenti',
    NOTE: 'Note',
    EDIT_BUTTON: 'Modifica',
    DELETE_BUTTON: 'Elimina',
    NO_TITLE: 'non avete ancora appuntamenti prenotati',
    NO_MESSAGE:
      'Per prenotare un appuntamento, controllate la vostra "lista degli eventi" per vedere se siete stati invitati a un evento, ' +
      "fate clic sulla scheda dell'evento per aprire il relativo calendario, selezionate uno slot sul calendario e compilate le informazioni richieste per prenotare.",
    DELETE_MODAL_TITLE: 'Cancella il tuo appuntamento',
    ADMIN_DELETE_MODAL_TITLE: "Cancella l'appuntamento",
    DELETE_MODAL_BUTTON_CANCEL: 'Indietro',
    DELETE_MODAL_BUTTON_DELETE: 'Cancella Appuntamento',
    ADMIN_DELETE_MODAL_TEXT:
      "L'appuntamento dell'utente sarà cancellato. Questa azione non può essere annullata. Sei sicuro?",
    DELETE_MODAL_TEXT:
      "L'appuntamento sarà cancellato. Questa azione non può essere annullata. Sei sicuro?",
    EDIT_MODAL_TITLE: 'Modifica il tuo appuntamento',
    EDIT_MODAL_SUBTITLE:
      "È possibile modificare la data e l'ora dell'appuntamento, nonché il messaggio lasciato. " +
      'Per inviare la prenotazione, fare clic sul pulsante "Salva appuntamento modificato". I campi contrassegnati da * sono obbligatori.',
    EDIT_MODAL_BUTTON_ONE_TEXT: 'Indietro',
    EDIT_MODAL_BUTTON_TWO_TEXT: "Salva l'appuntamento",
  },
  INVITATIONS: {
    TITLE: 'Elenco degli eventi a cui sei stato invitato',
    VIEW_CALENDAR: 'Vai al Calendario',
    NO_TITLE: 'Non ci sono ancora eventi a cui siete invitati.',
    MIN_BOOKING_ALERT:
      "È necessario prenotare entro le {{hours}} ore precedenti all'orario di inizio dell'evento.",
    NO_MESSAGE:
      "Se pensate di essere stati invitati a un evento, ma non appare qui, controllate la presenza dell'e-mail di invito " +
      "sul vostro client di posta elettronica e aprite il link dell'invito.",
    APPOINTMENT_DATE: 'Data appuntamento *',
    APPOINTMENT_TIME: 'Orario appuntamento *',
    APPOINTMENT_PRESENCE_TYPE: 'Tipo presenza *',
  },
  EVENT: {
    MALE: 'UOMO',
    FEMALE: 'DONNA',
    IN_PRESENCE: 'In Presenza',
    REMOTE: 'Da Remoto',
  },
  LOADING_MESSAGE: {
    TITLE: 'Caricamento',
    MESSAGE: 'Si prega di attendere...',
  },
  RESPONSE_ERROR_MESSAGE: {
    TITLE: 'Errore',
    MESSAGE: 'Qualcosa è andato storto...',
  },
  PRIVACY_POLICY: {
    TITLE: 'Informativa privacy',
  },
  PRIVACY_POLICY_BANNER: {
    TEXT: 'Questo sito utilizza cookie tecnici di sessione per garantire la corretta fruizione della navigazione. Per maggiori dettagli, si prega di consultare l’Informativa Privacy',
    BUTTON_CONFIRM: 'OK, ho capito',
  },
  CALENDAR: {
    ALREADY_BOOKED:
      'ATTENZIONE: Avete già una prenotazione attiva. Prima di effettuare una nuova prenotazione occorre disdire la precedente.',
  },
  TIMEZONE: {
    LOCATION: '(Orario evento)',
    LOCAL: '(Orario locale)',
    SWITCH_TIME_LOCATION: 'Mostra orario di {{location}}',
    LOCATION_SHORT: '(evento)',
    LOCAL_SHORT: '(locale)',
  },
};
