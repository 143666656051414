// Import the useTranslations hook for internationalization
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// Import necessary React components and hooks
import {useLocation} from 'react-router-dom';

// Import the SideModal component
import {Box, Typography} from '@mui/material';
// Import Box and Typography components from Material-UI
import TextField from '@mui/material/TextField';
// Import the TextField component from Material-UI
import {useTranslations} from '@vidiemme/react-i18n';

import {PrintDate} from '@/atoms/PrintDate';
import {useToast} from '@/hooks/toast';
// Import the useToast hook for displaying toasts
import {useNavigation} from '@/navigation/useNavigation';
import {BookingWithGuests} from '@/organisms/BookingWithGuests';
import {BookingGuest} from '@/store/bookings';
// Import the useNavigation hook for navigation handling
import {PutBookingOptions, usePutBooking} from '@/store/bookings';
// Import functions and options for updating bookings
import {Category} from '@/store/categories';
import {INPUT_PROPS, CHARACTER_LIMIT} from '@/store/inputProps';
// Import Category enumeration
import {SideModal} from '@/templates/SideModal';

// Import the useLocation hook for accessing URL location
import {ClothingSelect} from './ClothingSelect';
// Import the ClothingSelect component
import {CorporateSelect} from './CorporateSelect';
// Import the CorporateSelect component
import {OrdinarySelect} from './OrdinarySelect';
// Import the OrdinarySelect component
import {useStyle} from './styles';

const BookingEdit: FunctionComponent = () => {
  const {showSuccessToast, showErrorToast} = useToast(); // Get functions for displaying success and error toasts
  const {t} = useTranslations(); // Get the translation function
  const {goToPreviousPage} = useNavigation(); // Get the function for navigating back
  const {state} = useLocation(); // Get the current URL location's state

  const eventCategory = state.appointment.slot.eventDay.event.category.name;

  const [textFieldValue, setTextFieldValue] = useState<string>(
    state.appointment.note,
  ); // State for the appointment's note

  const [slotId, setSlotId] = useState<number>(state.appointment.slotId); // State for the appointment's slot ID

  const [guests, setGuests] = useState<BookingGuest[]>(
    state.appointment.guests || [],
  ); // Booking guests State

  const [slotLimit, setSlotLimit] = useState<number>(
    state.appointment.slot.slotLimit || 0,
  );

  const [bookedSpots, setBookedSpots] = useState<number>(
    state.appointment.slot?.bookedSpots || 0,
  );

  const [initialGuestsCount, setInitialGuestsCount] = useState<number>(
    state.appointment.guests?.length || 0,
  );

  const [validateError, setValidateError] = useState(false);

  const [bookingOptions, setBookingOptions] = useState<PutBookingOptions>({
    event_id: state.appointment.eventId,
    slot_id: slotId,
    note: textFieldValue,
    ...(eventCategory === Category.ORDINARY_OPENING ? {guests} : {}),
  }); // State for the booking options

  const [isDisabled, setIsDisabled] = useState<boolean>(false); // State used to disable update button in specific conditions

  const setSlotIdHandler = useCallback((id: number): void => {
    setSlotId(id);
  }, []);

  useEffect((): void => {
    setBookingOptions({
      event_id: state.appointment.eventId,
      slot_id: slotId,
      note: textFieldValue,
      ...(eventCategory === Category.ORDINARY_OPENING ? {guests} : {}),
    });
  }, [
    slotId,
    state.appointment.eventId,
    state.appointment.slotId,
    textFieldValue,
    eventCategory,
    guests,
  ]);

  const {
    editBookingDetailsBox,
    editBookingDetailsTitle,
    editBookingDropDownBox,
    editBookingTextField,
  } = useStyle(); // Get style-related functions

  const {loading, error, response, run} = usePutBooking(
    state.appointment.id,
    bookingOptions,
  ); // Use the usePutBooking hook to update the booking

  const selectsBody = useMemo(() => {
    // Determine which type of select component to render based on the appointment's category
    switch (eventCategory) {
      case Category.CORPORATE:
        return (
          <CorporateSelect
            appointment={state.appointment}
            setSlotIdHandler={setSlotIdHandler}
          />
        );
      case Category.CLOTHING:
        return (
          <ClothingSelect
            appointment={state.appointment}
            setSlotIdHandler={setSlotIdHandler}
            disableButtonHandler={setIsDisabled}
          />
        );
      default:
        return (
          <OrdinarySelect
            appointment={state.appointment}
            setSlotIdHandler={setSlotIdHandler}
            setGuests={setGuests}
            setSlotLimit={setSlotLimit}
            setBookedSpots={setBookedSpots}
            setInitialGuestsCount={setInitialGuestsCount}
            disableButtonHandler={setIsDisabled}
          />
        );
    }
  }, [setSlotIdHandler, state.appointment, eventCategory]);

  const saveButtonHandler = useCallback(() => {
    if (run) {
      return run();
    }
    return null;
  }, [run]);

  const textFieldValueHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setTextFieldValue(event.target.value);
    },
    [],
  );

  const dateProps = useMemo(() => {
    return {
      date: state.date,
      startHour: state.startHour,
      endHour: state.endHour,
      timezoneLocation: state.appointment.slot.eventDay.event.location.timezone,
    };
  }, [
    state.appointment.slot.eventDay.event.location.timezone,
    state.date,
    state.endHour,
    state.startHour,
  ]);

  useEffect(() => {
    if (error) {
      showErrorToast({message: t('BOOKING_MODAL.RESPONSE_ERROR')});
    } else if (response) {
      showSuccessToast({message: t('BOOKING_MODAL.RESPONSE_SUCCESS')});
      goToPreviousPage();
    }
  }, [error, goToPreviousPage, response, showErrorToast, showSuccessToast, t]);

  return (
    <SideModal
      title={t('APPOINTMENTS.EDIT_MODAL_TITLE')}
      subTitle={t('APPOINTMENTS.EDIT_MODAL_SUBTITLE')}
      closeHandler={goToPreviousPage}
      textButtonOne={t('APPOINTMENTS.EDIT_MODAL_BUTTON_ONE_TEXT')}
      CTAButtonOne={goToPreviousPage}
      textButtonTwo={t('APPOINTMENTS.EDIT_MODAL_BUTTON_TWO_TEXT')}
      CTAButtonTwo={saveButtonHandler}
      buttonTwoDisabled={isDisabled || loading || validateError}
      buttonTwoLoading={loading}>
      <Box sx={editBookingDetailsBox}>
        <Typography variant={'titleVersace2'} sx={editBookingDetailsTitle}>
          {state.appointment.slot.eventDay.event.name}
        </Typography>
        <PrintDate dateProps={dateProps} variant={'bodyRegular'} />
      </Box>
      <Box sx={editBookingDropDownBox}>{selectsBody}</Box>

      {/*eventCategory === Category.ORDINARY_OPENING && (
        <BookingWithGuests
          guests={guests}
          setGuests={setGuests}
          setValidateError={setValidateError}
          slotLimit={slotLimit}
          bookedSpots={bookedSpots}
          initialGuestsCount={initialGuestsCount}
        />
      )*/}

      <Box>
        <TextField
          sx={editBookingTextField}
          fullWidth
          label={t('BOOKING_MODAL.MESSAGE')}
          id="fullWidth"
          minRows={6}
          maxRows={6}
          multiline={true}
          value={textFieldValue}
          onChange={textFieldValueHandler}
          inputProps={INPUT_PROPS}
          helperText={`${textFieldValue.length}/${CHARACTER_LIMIT}`}
        />
      </Box>
    </SideModal>
  );
};

export default BookingEdit;
